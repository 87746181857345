.navbar{
    background-color:white !important;
    color: #003C43;
}
.heade{
    background-color: #003C43;
    color: white;
}
.links a{
    margin-left: 50px;
    color: #003C43;
      font-family: "Archivo", sans-serif;
      font-weight: 600
  }
.dropdown-menu[data-bs-popper]{
    left: -340px;
}
.dropdown-item {
    width: fit-content;
}
.links a:hover{
    color: #0F9696;
}
.dropdown-menu.show{
    width: 55rem;
    background-color: #F0EDCC;
    color: black;
}
.vl {
    border-left: 3px solid rgb(255, 255, 255);
    height: 50px;
  }
  #phoneheader{
    font-family: 'secondaryfont', sans-serif;
    margin-left: 6.5rem;
  }
  .navprod1 h4{
    font-size: 15px;
    color: #003c43;
    font-family: "Changa", sans-serif;
  }

  .navprod1 h6{
    font-size: 12px;
    color: black;
    font-family: "Changa", sans-serif;
  }
  .navprodimg:hover img {
    opacity: .5;
  } 
  .dropdown-menu .dropdown-item:hover {
    background-color: transparent !important;
}
#keybo img{ rotate: 90deg;
  margin-left: -15px;
  margin-top: 10px;
}
.navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Border at the bottom */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Shadow at the bottom */
}
  /* Media query for mobile devices */
@media (max-width: 768px) {
    #phoneheader{
        font-family: 'secondaryfont', sans-serif;
        margin-left: 5%;
      }
    .links a{
        margin-left:0px;
        color: #003C43;
        font-family: "Archivo", sans-serif;
        font-weight: 600
    
    }
    .dropdown-menu.show{
        width: 100%;
        background-color: #f0edcc;
        color: black;
    }
    #keybo{
      margin-left: -20px;
    }
    #keybo img{
      margin-top: 10px;
      width: 120px;
      rotate: 90deg;
    }
    /* -------------------------------------- */
    /* Custom CSS for the language dropdown */
    #collapsible-nav-dropdown-lang .dropdown-menu.show {
      background-color: #f8f9fa !important; /* Change this to your desired style */
      padding: 10px;
    }
    .navbar #collapsible-nav-dropdown-lang + .dropdown-menu.show {
      background-color: #f8f9fa !important; /* Change this to your desired style */
      padding: 10px !important;
    }
    
    .navbar .dropdown-menu.show:nth-of-type(2) .dropdown-item {
      color: #003C43; /* Adjust text color */
      font-family: "Archivo", sans-serif; /* Adjust font */
    }
    
    .navbar .dropdown-menu.show:nth-of-type(2) .dropdown-item:hover {
      background-color: #e9ecef; /* Adjust hover background color */
    }

  }