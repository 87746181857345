.whysection {
    width: 100%;
   background-color: #003c43;
    color: white;
    font-family: "Archivo", sans-serif;
    font-style: normal;
    padding: 85px;
}
#elc{
    border-radius: 15px;
    padding: 12px;
}
.iconwhy {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.iconwhy img {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    transition: width 0.3s ease; /* Adding transition for smooth effect */
}

.iconwhy:hover img {
    width: 65px;
    height: 65px; /* Keeping the height consistent */
}
#bolt {
    font-weight: 500;
}

.iconwhy p {
    font-family: "Archivo", sans-serif;

  font-size: 18px;
  color: white;
  font-style: normal;
}
.d-flex {
    display: flex;
}

.align-items-stretch {
    align-items: stretch;
}

.justify-content-center {
    justify-content: center;
}

.mb-3 {
    margin-bottom: 1rem;
}
@media (max-width: 768px) {
.whysection{
    padding: 5px;
}
.colmarg{
    margin: 5px;
}
#whyt{
margin-left: 25px;
}
#whyb{
    margin: auto;
    }

}