.video-section {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
  }
  
  .video-container {
    flex: 1 1 calc(33.333% - 16px);
    box-sizing: border-box;
    margin: 8px;
  }
  
  .video-container iframe {
    width: 100%;
    height: 100%;
  }
  