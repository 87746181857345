
.articleWrapper {
    width: 265px;
    transition: 0.15s all ease-in-out;
    border-radius: 10px;
    padding: 5px;
    border: 4px solid;
    border-image: radial-gradient(circle, rgba(75,166,209,1) 0%, rgba(16,68,115,1) 99%);
    border-image-slice: 1;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .articleWrapper:hover {
    box-shadow: 10px 10px 0 #4e84ff, 20px 20px 0 #4444bd;
    border-color: transparent;
    transform: translate(-20px, -20px);
  }
  
  .articleWrapper:active {
    box-shadow: none;
    transform: translate(0, 0);
  }
  
  .types {
    gap: 10px;
    display: flex;
    place-content: flex-start;
  }
  
  .roundedLg {
    border-radius: 10px;
  }
  
  .articleWrapper:hover .projectHover {
    transform: rotate(-45deg);
    background-color: #a6c2f0;
  }
  
  .projectInfo {
    padding-top: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .projectTitle {
    font-size: 1em;
    margin: 0;
    font-family: "Sansita", sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: black;
  }
  
  .flexPr {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .projectType {
    background: #b2b2fd;
    color: #1a41cd;
    font-weight: bold;
    padding: 0.3em 0.7em;
    border-radius: 15px;
    font-size: 12px;
    letter-spacing: -0.6px;
  }
  
  .projectHover {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 9px;
    transition: all 0.3s ease;
  }
  
  .containerProject {
    width: 100%;
    height: 250px;
    background: gray;
  }
  @media (max-width: 768px) {
  .articleWrapper{
    margin-left: 50px;
    margin-top: 15px;
  }
  }