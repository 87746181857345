@font-face {
    font-family: 'secondaryfont';
    src: url('./Helvetica-BoldOblique.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'primaryfont';
    src: url('./MASQUE__.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }