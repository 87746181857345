
.about {
  overflow: hidden;
}
#imageabout {
    border-radius: 40px;
    width: 550px;
}
.firstsectionabout{
    margin: 1rem;
    margin-top: 40px;
    background-color: #0F9696;
    border-radius: 10px;
}
.headline-container {
    display: block;
    align-items: center;
  }
  .anim {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Full viewport height */
    text-align: center; /* Center align text within the h1 element */
  }
  
  .anim img {
    margin-right: 20px; /* Space between the image and text */
  }
  .anim h1 {
    font-family: "Archivo", sans-serif;
    font-weight: 400;   font-size: 40px;
  color:#003c43;  }
  
  .headline-container p{
    margin-top: 10px;
    color: #ffffff;
    font-family: "Archivo", sans-serif;
    font-optical-sizing: auto;
        font-weight: 200;
    font-size: 18px;
    line-height: 30px;
  }
  .headline {  
    position: relative;
    margin: 0;
    padding-left: 20px; /* Adjust as needed for spacing */
  }
  .headline{
    margin-top: 10px;
    color: #ffffff;
    font-family: "Archivo", sans-serif;
    font-weight: 400;
    font-style: normal;    font-size: 20px;
  }
  .headline::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 15px; /* Adjust as needed for line length */
    height: 2px; /* Adjust as needed for line thickness */
    background-color: rgb(255, 255, 255); /* Adjust as needed for line color */
  }
.secondsection {
  background-color: #0F9696 ;
  border-radius: 20px;
margin: 1rem;
}
  .secondcontent{
    padding: 10px;
    color: white;
  }
.momo{
  display: flex;
}

  #secondimage{
   width:550px ;
   border-radius: 20px;
  }


.secondcontent p{
  color: #ffffff;
  font-family: "Archivo", sans-serif;
        font-optical-sizing: auto;
        font-weight: 200;
    font-size: 18px;
  line-height: 28px;
}
  @media (max-width: 768px) {
    #imageabout{
      width: 300px;
    }
    
  #secondimage{
    width:300px ;
    border-radius: 20px;
   }
   .momo{
    display: block;
  }
  
    .firstsectionabout{
      display: flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      text-align: center; /* Center align text within the h1 element */    }
      .headline::before{
        left: 100px;
      }
      .secondsection{
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        text-align: center; /* Center align text within the h1 element */    }
      .anim {
        display: block;
        justify-content: center;
        align-items: center;
        height: 40vh; /* Full viewport height */
        text-align: center; /* Center align text within the h1 element */
      }
    }