.product-info-container {
  max-width: 500px; /* Add this line to set the maximum width */
  display: flex;
  flex-direction: column;
  align-items: center;
}


.product-image {
  max-width: 80%;
  width: 300px;
  /* margin-left: 25px; */
  height: auto;
  object-fit: cover;
  transition: transform 1s, box-shadow 1s;
}

.product-image:hover {
  transform: scale(1.1);
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); */
}

.product-name {
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 40px;
}
.product-name:hover{
  transform:scale((1.1));
}

#bol {
  font-weight: 500;
}

.product-description {
  font-size: 2.5rem;
  /* font-weight: 500; */
  text-transform: capitalize;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.product-price {
  font-size: 20px;
  color: #007bff;
}

.image-desc-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.pdkolo {
  margin-top: 100px;
  margin-bottom: 100px;
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* height: 80vh !important; */
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-image-container{
  height: 85vh;
}

video{
  margin: 30px 0px ;
  width: 100% !important;
  height: 70vh !important;
}

.video-dash{
  margin: 0;
  width: 120px !important;
  height: 120px !important;
}

@media (max-width: 768px) {
  .pdkolo {
      margin-top: 40px;
      margin-bottom: 40px;
  }

  .product-image {
      max-width: 80%;
      width: 250px;
      margin-left: 50px;
      height: auto;
      object-fit: cover;
      transition: transform 1s, box-shadow 1s;
  }
  #social_swiper .swiper-slide img {
    display: block;
    width: 20%;
    height: 20%;
    object-fit: cover;
  }
  



}
