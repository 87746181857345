/* ProductCard.css */
.product-card {
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 440px; /* Adjusted height */
  width: 300px; /* Fixed width */
}

.product-card__image {
  height: 70%; /* Adjusted height */
  overflow: hidden;
}

.product-card__image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover container */
}

.product-card__content {
  background-color: #f9f9f9;
  padding: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-grow: 1; /* Allow content to grow to fill remaining space */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center; /* Center text horizontally */
}

.product-card__title {
  font-size: 15px;
  font-family: "Sansita", sans-serif;
}

.product-card__button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 10px; /* Adjust top margin */
}

.product-card__button:hover {
  background-color: #0056b3;
}

.product-card__description {
  font-size: 14px;
}

@media (max-width: 768px) {
  .product-card {
    margin-left: 25px;
  }
}
