.maploc{
    border-radius: 15px;
    margin: 15px;
    width: 500px;
    height: 380px;
    border: 0;
    padding: 0;
}
.register-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 450px;
    margin: 15px;
    padding: 20px;
    border-radius: 20px;
    position: relative;
    background-color: #1a1a1a;
    color: #fff;
    border: 1px solid #333;
  }
  
  .register-title {
    font-size: 22px;
    font-weight: 600;
    font-family: "Fugaz One", sans-serif;
    font-weight: 400;
    letter-spacing: -1px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;
    color: #00bfff;
  }
  
  .register-title::before,
  .register-title::after {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50%;
    left: 0px;
    background-color: #00bfff;
  }
  
  .register-title::before {
    width: 18px;
    height: 18px;
  }
  
  .register-title::after {
    width: 18px;
    height: 18px;
    animation: pulse 1s linear infinite;
  }
  
  .register-message, 
  .register-signin {
    font-size: 14.5px;
    color: rgb(255, 255, 255);
  }
  
  .register-signin {
    text-align: center;
  }
  
  .register-signin a {
    color: #00bfff;
  }
  
  .register-signin a:hover {
    text-decoration: underline royalblue;
  }
  
  .register-flex {
    display: flex;
    width: 100%;
    gap: 6px;
  }
  
  .register-label {
    position: relative;
  }
  
  .register-input {
    background-color: #333;
    color: #fff;
    width: 100%;
    padding: 20px 5px 5px 10px;
    outline: 0;
    border: 1px solid rgba(105, 105, 105, 0.397);
    border-radius: 10px;
  }
  
  .register-input + span {
    color: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 10px;
    top: 0px;
    font-size: 0.9em;
    cursor: text;
    transition: 0.3s ease;
  }
  
  .register-input:placeholder-shown + span {
    top: 12.5px;
    font-size: 0.9em;
  }
  
  .register-input:focus + span,
  .register-input:valid + span {
    color: #00bfff;
    top: 0px;
    font-size: 0.7em;
    font-weight: 600;
  }
  
  .register-submit {
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    transform: 0.3s ease;
    background-color: #00bfff;
  }
  
  .register-submit:hover {
    background-color: #00bfff96;
  }
  


  .unique-card {
    position: relative;
    width: 300px;
    height: 200px;
    background-color:#0F9696;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  .unique-card2 {
    cursor: pointer;
    position: relative;
    width: 120px;
    height: 120px;
    background-color:#003c43;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .unique-card:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  .carwid{
    width: 250px;
  }
  .card-content {
    position: absolute;
   
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 2;
    transition: color 0.3s;
  }
  
  .unique-card:hover .card-content {
    color: #ffc55a;
  }
  
  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(52, 73, 94, 0.7);
    border-radius: 15px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .unique-card:hover .background-overlay {
    opacity: 1;
  }
  
  .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .card-title img {
    width: 32px;
  }
  .card-description {
    font-size: 16px;
  }
  

  .social-main {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
  
  .social-up {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }
  
  .social-down {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }
  
  .social-card1, .social-card2, .social-card3, .social-card4 {
    width: 90px;
    height: 90px;
    outline: none;
    border: none;
    background: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: .2s ease-in-out;
  }
  
  .social-card1 {
    border-radius: 90px 5px 5px 5px;
  }
  
  .social-instagram {
    margin-top: 1.5em;
    margin-left: 1.2em;
    fill: #cc39a4;
  }
  
  .social-card2 {
    border-radius: 5px 90px 5px 5px;
  }
  
  .social-twitter {
    margin-top: 1.5em;
    margin-left: -.9em;
    fill: #03f437;
  }
  
  .social-card3 {
    border-radius: 5px 5px 5px 90px;
  }
  
  .social-github {
    margin-top: -.6em;
    margin-left: 1.2em;
  }
  
  .social-card4 {
    border-radius: 5px 5px 90px 5px;
  }
  
  .social-discord {
    margin-top: -.9em;
    margin-left: -1.2em;
    fill: #4267B2;
  }
  
  .social-card1:hover {
    cursor: pointer;
    transform: scale(1.1);
    background-color: #cc39a4;
  }
  
  .social-card1:hover .social-instagram {
    fill: white;
  }
  
  .social-card2:hover {
    cursor: pointer;
    transform: scale(1.1);
    background-color: #03f403;
  }
  
  .social-card2:hover .social-twitter {
    fill: white;
  }
  
  .social-card3:hover {
    cursor: pointer;
    transform: scale(1.1);
    background-color: #4267B2;
  }
  
  .social-card3:hover .social-github {
    fill: white;
  }
  .secondrow{
    margin: 30px;
  }
.header img{
    width: 100%;
}
  .fancy {
    --b: 6px;   /* control the border thickness */
    --w: 80px;  /* control the width of the line*/
    --g: 15px;  /* control the gap */
    --c: #0B486B;
   
    width: fit-content;
    padding: 0 1em;
    line-height: 1.6em;
    border: 1px solid;
    color: #fff;
    background: 
      conic-gradient(from   45deg at left ,var(--c) 25%,#0000 0) 0,
      conic-gradient(from -135deg at right,var(--c) 25%,#0000 0) 100%;
    background-size: 51% 100%;
    background-origin: border-box;
    background-repeat: no-repeat;
    border-image: 
       linear-gradient(
         #0000      calc(50% - var(--b)/2),
         var(--c) 0 calc(50% + var(--b)/2),
         #0000    0) 
      1/0 var(--w)/calc(var(--w) + var(--g));
    margin-inline: auto;
  }
  
  
  
 .fancy p{
    font-size: 1.3rem;
    text-align: justify;
    margin-inline: max(20px, 50% - 800px/2);
  }

  .fancy h1 {
    font-size: 2.3rem;
    
  }
  .fancy h2 {
    font-size: 1.8rem;

  }
#dividerfont{
    font-family: "Fugaz One", sans-serif;
    font-weight: 400;
}
  
  .social-card4:hover {
    cursor: pointer;
    transform: scale(1.1);
    background-color: black;
  }
  
  .social-card4:hover .social-discord {
    fill: white;
  }
  
  
  @keyframes pulse {
    from {
      transform: scale(0.9);
      opacity: 1;
    }
  
    to {
      transform: scale(1.8);
      opacity: 0;
    }
  }
  @media (max-width: 768px) {
    .maploc{
        width: 90%;
    }
    .secondrow{
        margin: 30px;
      }
      .unique-card{
        margin-top: 20px;
      }
}