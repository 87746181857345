.where-header{
    height: 65vh;
    width: 100%;
    background: url('../../assets/imgs/where.png') no-repeat center center;
    background-size: cover;
    background-color: #003C43;
    margin-bottom: 20px;
}
.where-header-content{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.where-header-content h3{
    color: #FFFFFF;
    font-weight: 700;
    font-size: 2.7rem;
}
.where-header-content p{
    color: #FFFFFF;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
}
.where-header-content select{
    background-color: #FFFFFF;
    padding: 2px;
    color: #003C43;
    font-weight: 500;
    outline: none;
    border: none;
}
.where-header-content select option {
    background-color: #FFFFFF
}
.where-header-content select:hover{
    background-color: yellowgreen;
}

/* Card.css */

.card-container {
    position: relative;
    /* width: 300px; Adjust the width as needed */
    height: 200px; /* Adjust the height as needed */
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.card-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

/* .card-container:hover {
    transform: scale(1.05);
} */

.card-name {
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 60, 67, 0.8);
    /* background-color:#003C43; Yellow background */
    color: #000; /* Text color */
    padding: 10px;
    text-align: center;
    font-size: 1.2em;
    transform: translateY(100%);
    transition: transform 0.3s ease;
}
.card-name-hover{
    height: 100%;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-container:hover .card-name {
    transform: translateY(0);
}

.p-dropdown-label, .p-dropdown-trigger{
    display: none;
}
.partners-container{
    display: flex;
    justify-content: center;
}