.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: #003c43;
  color: white;
  font-family: "Archivo", sans-serif;
  font-weight: 500
}

.card-img-top {
height: 354px;}

.card-body {
  flex: 1;
}

.card:hover .card-body {
  background: rgb(75,166,209);
  background: radial-gradient(circle, rgba(75,166,209,1) 0%, rgba(16,68,115,1) 99%);
 color: wheat;
  transition-duration: 0.5s;
  font-weight: 600;
}
.custom-card {
    position: relative;
    width: 100%;
    height: 150px;
    background-color: #0284c7;
    color: #f8fafc;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem;
    overflow: hidden;
  }
  .producttitle{
    font-family: 'primaryfont', sans-serif;
  }
  .custom-card svg {
    position: absolute;
    opacity: 0.3;
    transform: rotate(-12deg);
    bottom: -3rem;
    right: -3rem;
    width: 10rem;
    height: 10rem;
    stroke: currentColor;
  }
  
  .custom-card .title {
    font-size: 2rem;
    font-weight: 800;
    text-align: center;
  }
  
  .custom-card .discount-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .custom-card .discount-info p {
    margin: 0;
  }
  
  .custom-card .discount-info .main-text {
    font-size: 1rem;
    font-weight: 800;
  }
  
  .custom-card .discount-info .sub-text {
    font-size: 0.75rem;
    position: relative;
    display: inline-block;
  }
  
  .custom-card .discount-info .sub-text::before,
  .custom-card .discount-info .sub-text::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 3rem;
    height: 0.125rem;
    background-color: #e5e7eb;
  }
  
  .custom-card .discount-info .sub-text::before {
    left: -4rem;
  }
  
  .custom-card .discount-info .sub-text::after {
    right: -4rem;
  }
  
  .custom-card .discount {
    font-size: 4rem;
    font-weight: 800;
    transform: skew(-12deg, -12deg);
    margin: 0;
  }
